import React, { FC } from 'react';
import './styles.css';

const Dalia: FC = () => {
    return (
        <>
            <h1>Workout Routine</h1>
            <h2>Day 1: Back & Biceps</h2>
            <div className="exercise">
                <h3>1. Lat Pulldown – 3x10-12</h3>
                <img
                    src="https://cdn.shopify.com/s/files/1/0449/8453/3153/files/Lat_Pulldown_Muscles_Worked_fabda5f7-f40a-4e3b-a6b0-5f442a9d091f.png?v=1691567490"
                    alt="Lat Pulldown"
                ></img>
            </div>
            <div className="exercise">
                <h3>2. Seated Cable Row – 3x10-12</h3>
                <img
                    src="https://cdn-0.weighttraining.guide/wp-content/uploads/2016/05/Seated-cable-row-new-resized.png?ezimgfmt=ng%3Awebp%2Fngcb4"
                    alt="Seated Cable Row"
                ></img>
            </div>
            <div className="exercise">
                <h3>3. Lower Back Machine – 3x10-12 each side</h3>
                <img
                    src="https://steelsupplements.com/cdn/shop/articles/shutterstock_1705063531_1000x.jpg?v=1642058677"
                    alt="Single-Arm Dumbbell Row"
                ></img>
            </div>
            <div className="exercise">
                <h3>4. Dumbbell Bicep Curls – 2x12-15</h3>
                <img
                    src="https://cdn.shopify.com/s/files/1/1497/9682/files/2_dd805047-4f47-4ebf-ad7b-3336dd3b69d2.jpg?v=1658851624"
                    alt="Bicep Curls"
                ></img>
            </div>
            <div className="exercise">
                <h3>5. Hammer Curls – 2x12-15</h3>
                <img
                    src="https://cdn-0.weighttraining.guide/wp-content/uploads/2016/11/Dumbbell-Hammer-Curl-resized.png?ezimgfmt=ng%3Awebp%2Fngcb4"
                    alt="Hammer Curls"
                ></img>
            </div>

            <h2>Day 2: Chest, Shoulders & Triceps</h2>
            <div className="exercise">
                <h3>1. Incline Dumbbell Press – 4x8-10</h3>
                <img
                    src="https://cdn-0.weighttraining.guide/wp-content/uploads/2016/11/incline-dumbbell-bench-press-resized.png?ezimgfmt=ng%3Awebp%2Fngcb4"
                    alt="Incline Dumbbell Press"
                ></img>
            </div>
            <div className="exercise">
                <h3>2. Chest Press (Machine or Dumbbells) – 3x10-12</h3>
                <img
                    src="https://liftmanual.com/wp-content/uploads/2023/04/machine-inner-chest-press.jpg"
                    alt="Chest Press"
                ></img>
            </div>
            <div className="exercise">
                <h3>3. Lateral Raises – 3x12-15</h3>
                <img
                    src="https://cdn-0.weighttraining.guide/wp-content/uploads/2016/05/dumbbell-lateral-raise-resized.png?ezimgfmt=ng%3Awebp%2Fngcb4"
                    alt="Lateral Raises"
                ></img>
            </div>
            <div className="exercise">
                <h3>4. Dumbbell Shoulder Press (or Machine) – 3x10-12</h3>
                <img
                    src="https://liftmanual.com/wp-content/uploads/2023/04/lever-seated-shoulder-press.jpg"
                    alt="Dumbbell Shoulder Press"
                ></img>
            </div>
            <div className="exercise">
                <h3>5. Triangle Triceps Pushdown – 3x8-10</h3>
                <img
                    src="https://static.strengthlevel.com/images/exercises/tricep-pushdown/tricep-pushdown-800.jpg"
                    alt="Triangle Triceps Pushdown"
                ></img>
            </div>
            <div className="exercise">
                <h3>6. Overhead Triceps Extensions – 3x12-15</h3>
                <img
                    src="https://cdn-bcpoc.nitrocdn.com/TKqfpmIjZIVcmnkUTvWEFXVhMTTVDydg/assets/images/optimized/rev-e10163d/homegymreview.co.uk/wp-content/uploads/exercises/22481101-Cable-Overhead-Triceps-Extension-rope-attachment-female_Upper-Arms_max-scaled.jpg"
                    alt="Overhead Triceps Extensions"
                ></img>
            </div>

            <h2>Day 3: Lower Body (Glutes & Legs Focused)</h2>
            <div className="exercise">
                <h3>1. Squats (Barbell or Smith) – 4x8-10</h3>
                <img
                    src="https://weighttraining.guide/wp-content/uploads/2016/10/barbell-squat-resized-FIXED-2.png"
                    alt="Squats"
                ></img>
            </div>
            <div className="exercise">
                <h3>2. Romanian Deadlifts (Dumbbells or Barbell) – 3x10-12</h3>
                <img
                    src="https://www.hertssportsvillage.co.uk/news-images/2022-Nov/rdl--4995.jpg"
                    alt="Romanian Deadlifts"
                ></img>
            </div>
            <div className="exercise">
                <h3>3. Hip Thrusts – 4x8-10</h3>
                <img
                    src="https://www.hertssportsvillage.co.uk/news-images/2023-Apr/hip-thrust--5180.jpg"
                    alt="Hip Thrusts"
                ></img>
            </div>
            <div className="exercise">
                <h3>4. Bulgarian Split Squats – 3x10-12 each leg</h3>
                <img
                    src="https://www.burnthefatinnercircle.com/members/images/2003b.png?cb=20250102035912"
                    alt="Bulgarian Split Squats"
                ></img>
            </div>
            <div className="exercise">
                <h3>5. Leg Curls (Machine) – 3x12-15</h3>
                <img
                    src="https://weighttraining.guide/wp-content/uploads/2016/10/seated-leg-curl-resized.png"
                    alt="Leg Curls"
                ></img>
            </div>
            <div className="exercise">
                <h3>6. Quadriceps Machine – 3x10-12</h3>
                <img
                    src="https://www.burnthefatinnercircle.com/members/images/1636.jpg?cb=20250102035849"
                    alt="Quadriceps Machine"
                ></img>
            </div>
            <div className="exercise">
                <h3>7. Standing Calf Raises (maybe) – 3x15-20</h3>
                <img
                    src="https://weighttraining.guide/wp-content/uploads/2016/10/Lever-Standing-Calf-Raise-resized.png"
                    alt="Standing Calf Raises"
                ></img>
            </div>

            <h2>Day 4: Abs & Cardio</h2>
            <div className="exercise">
                <h3>1. Plank (Hold for Time or Weighted) – 3x30-60 sec</h3>
                <img
                    src="https://cdn-0.weighttraining.guide/wp-content/uploads/2021/06/Weighted-front-plank.png?ezimgfmt=ng%3Awebp%2Fngcb4"
                    alt="Plank"
                ></img>
            </div>
            <div className="exercise">
                <h3>2. Hanging Leg Raises – 3x10-12</h3>
                <img
                    src="https://kinxlearning.com/cdn/shop/files/exercise-21_923x.jpg?v=1613154789"
                    alt="Hanging Leg Raises"
                ></img>
            </div>
            <div className="exercise">
                <h3>3. Russian Twists – 3x20 (total)</h3>
                <img
                    src="https://liftmanual.com/wp-content/uploads/2023/04/russian-twist.jpg"
                    alt="Russian Twists"
                ></img>
            </div>
            <div className="exercise">
                <h3>4. Cable or Weighted Crunches – 3x12-15</h3>
                <img
                    src="https://training.fit/wp-content/uploads/2020/01/kabelzugcrunches-800x448.png"
                    alt="Russian Twists"
                ></img>
            </div>
        </>
    );
};

export default Dalia;
