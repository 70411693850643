import React from 'react';
import { BottomNavbarSocialsContainer, BottomNavbarSocialsTitle } from './BottomNavbarSocials.style';
import { Link } from 'react-router-dom';

type BottomNavbarSocialsProps = {
    title: string;
    link?: string;
    downloadCV?: boolean;
    redirect?: boolean;
};

const BottomNavbarSocials: React.FC<BottomNavbarSocialsProps> = ({ title, link, downloadCV, redirect }) => {
    const handleClick = () => {
        if (downloadCV) {
            const downloadLink = document.createElement('a');
            downloadLink.href = '/Dragos Baci.pdf';
            downloadLink.download = 'Dragos_Baci_CV.pdf';
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        } else {
            window.open(link);
        }
    };

    if (redirect) {
        return (
            <Link to={'/dalia'}>
                <BottomNavbarSocialsContainer>
                    <BottomNavbarSocialsTitle>{title}</BottomNavbarSocialsTitle>
                </BottomNavbarSocialsContainer>
            </Link>
        );
    }

    return (
        <BottomNavbarSocialsContainer onClick={handleClick}>
            <BottomNavbarSocialsTitle>{title}</BottomNavbarSocialsTitle>
        </BottomNavbarSocialsContainer>
    );
};

export default BottomNavbarSocials;
